const recommendations = {
  // Potato diseases
  "Potato___Early_blight": {
    advice: [
      "recommendation.potatoEarlyBlight.disease",
      "recommendation.potatoEarlyBlight.step1",
      "recommendation.potatoEarlyBlight.step2",
      "recommendation.potatoEarlyBlight.step3",
      "recommendation.potatoEarlyBlight.step4",
      "recommendation.potatoEarlyBlight.step5",
      "recommendation.potatoEarlyBlight.step6",
    ],
  },
  "Potato___Late_blight": {
    advice: [
      "recommendation.potatoLateBlight.disease",
      "recommendation.potatoLateBlight.step1",
      "recommendation.potatoLateBlight.step2",
      "recommendation.potatoLateBlight.step3",
      "recommendation.potatoLateBlight.step4",
      "recommendation.potatoLateBlight.step5",
      "recommendation.potatoLateBlight.step6",
    ],
  },
  "Potato___healthy": {
    advice: [
      "recommendation.potatoHealthy.disease",
      "recommendation.potatoHealthy.step1",
      "recommendation.potatoHealthy.step2",
      "recommendation.potatoHealthy.step3",
      "recommendation.potatoHealthy.step4",
      "recommendation.potatoHealthy.step5",
      "recommendation.potatoHealthy.step6",
      "recommendation.potatoHealthy.step7",
      "recommendation.potatoHealthy.step8",
    ],
  },

  // Tomato diseases
  "Tomato___Bacterial_spot": {
    advice: [
      "recommendation.tomatoBacterialSpot.disease",
      "recommendation.tomatoBacterialSpot.step1",
      "recommendation.tomatoBacterialSpot.step2",
      "recommendation.tomatoBacterialSpot.step3",
      "recommendation.tomatoBacterialSpot.step4",
      "recommendation.tomatoBacterialSpot.step5",
      "recommendation.tomatoBacterialSpot.step6",
    ],
  },
  "Tomato___Early_blight": {
    advice: [
      "recommendation.tomatoEarlyBlight.disease",
      "recommendation.tomatoEarlyBlight.step1",
      "recommendation.tomatoEarlyBlight.step2",
      "recommendation.tomatoEarlyBlight.step3",
      "recommendation.tomatoEarlyBlight.step4",
      "recommendation.tomatoEarlyBlight.step5",
      "recommendation.tomatoEarlyBlight.step6",
    ],
  },
  "Tomato___Late_blight": {
    advice: [
      "recommendation.tomatoLateBlight.disease",
      "recommendation.tomatoLateBlight.step1",
      "recommendation.tomatoLateBlight.step2",
      "recommendation.tomatoLateBlight.step3",
      "recommendation.tomatoLateBlight.step4",
      "recommendation.tomatoLateBlight.step5",
      "recommendation.tomatoLateBlight.step6",
    ],
  },
  "Tomato___Leaf_Mold": {
    advice: [
      "recommendation.tomatoLeafMold.disease",
      "recommendation.tomatoLeafMold.step1",
      "recommendation.tomatoLeafMold.step2",
      "recommendation.tomatoLeafMold.step3",
      "recommendation.tomatoLeafMold.step4",
      "recommendation.tomatoLeafMold.step5",
      "recommendation.tomatoLeafMold.step6",
      "recommendation.tomatoLeafMold.step7",
    ],
  },
  "Tomato___Septoria_leaf_spot": {
    advice: [
      "recommendation.tomatoSeptoriaLeafSpot.disease",
      "recommendation.tomatoSeptoriaLeafSpot.step1",
      "recommendation.tomatoSeptoriaLeafSpot.step2",
      "recommendation.tomatoSeptoriaLeafSpot.step3",
      "recommendation.tomatoSeptoriaLeafSpot.step4",
      "recommendation.tomatoSeptoriaLeafSpot.step5",
      "recommendation.tomatoSeptoriaLeafSpot.step6",
    ],
  },
  "Tomato___Spider_mites Two-spotted_spider_mite": {
    advice: [
      "recommendation.tomatoSpiderMites.disease",
      "recommendation.tomatoSpiderMites.step1",
      "recommendation.tomatoSpiderMites.step2",
      "recommendation.tomatoSpiderMites.step3",
      "recommendation.tomatoSpiderMites.step4",
      "recommendation.tomatoSpiderMites.step5",
      "recommendation.tomatoSpiderMites.step6",
      "recommendation.tomatoSpiderMites.step7",
      "recommendation.tomatoSpiderMites.step8",
    ],
  },
  "Tomato___Target_Spot": {
    advice: [
      "recommendation.tomatoTargetSpot.disease",
      "recommendation.tomatoTargetSpot.step1",
      "recommendation.tomatoTargetSpot.step2",
      "recommendation.tomatoTargetSpot.step3",
      "recommendation.tomatoTargetSpot.step4",
      "recommendation.tomatoTargetSpot.step5",
    ],
  },
  "Tomato___Tomato_Yellow_Leaf_Curl_Virus": {
    advice: [
      "recommendation.tomatoYellowLeafCurlVirus.disease",
      "recommendation.tomatoYellowLeafCurlVirus.step1",
      "recommendation.tomatoYellowLeafCurlVirus.step2",
      "recommendation.tomatoYellowLeafCurlVirus.step3",
      "recommendation.tomatoYellowLeafCurlVirus.step4",
      "recommendation.tomatoYellowLeafCurlVirus.step5",
      "recommendation.tomatoYellowLeafCurlVirus.step6",
    ],
  },
  "Tomato___Tomato_mosaic_virus": {
    advice: [
      "recommendation.tomatoMosaicVirus.disease",
      "recommendation.tomatoMosaicVirus.step1",
      "recommendation.tomatoMosaicVirus.step2",
      "recommendation.tomatoMosaicVirus.step3",
      "recommendation.tomatoMosaicVirus.step4",
      "recommendation.tomatoMosaicVirus.step5",
      "recommendation.tomatoMosaicVirus.step6",
    ],
  },
  "Tomato___healthy": {
    advice: [
      "recommendation.tomatoHealthy.disease",
      "recommendation.tomatoHealthy.step1",
      "recommendation.tomatoHealthy.step2",
      "recommendation.tomatoHealthy.step3",
      "recommendation.tomatoHealthy.step4",
      "recommendation.tomatoHealthy.step5",
      "recommendation.tomatoHealthy.step6",
      "recommendation.tomatoHealthy.step7",
      "recommendation.tomatoHealthy.step8",
    ],
  },

  // Apple diseases
  "Apple___Apple_scab": {
    advice: [
      "recommendation.AppleAppleScab.disease",
      "recommendation.AppleAppleScab.step1",
      "recommendation.AppleAppleScab.step2",
      "recommendation.AppleAppleScab.step3",
      "recommendation.AppleAppleScab.step4",
      "recommendation.AppleAppleScab.step5",
      "recommendation.AppleAppleScab.step6",
      "recommendation.AppleAppleScab.step7",
    ],
  },
  "Apple___Black_rot": {
    advice: [
      "recommendation.AppleBlackRot.disease",
      "recommendation.AppleBlackRot.step1",
      "recommendation.AppleBlackRot.step2",
      "recommendation.AppleBlackRot.step3",
      "recommendation.AppleBlackRot.step4",
      "recommendation.AppleBlackRot.step5",
      "recommendation.AppleBlackRot.step6",
      "recommendation.AppleBlackRot.step7",
      "recommendation.AppleBlackRot.step8",
    ],
  },

  "Apple___healthy": {
    advice: [
      "recommendation.AppleHealthy.disease",
      "recommendation.AppleHealthy.step1",
      "recommendation.AppleHealthy.step2",
      "recommendation.AppleHealthy.step3",
      "recommendation.AppleHealthy.step4",
      "recommendation.AppleHealthy.step5",
      "recommendation.AppleHealthy.step6",
      "recommendation.AppleHealthy.step7",
      "recommendation.AppleHealthy.step8",
      "recommendation.AppleHealthy.step9",
    ],
  },
  "Apple___Cedar_apple_rust": {
    advice: [
      "recommendation.AppleCedarAppleRust.disease",
      "recommendation.AppleCedarAppleRust.step1",
      "recommendation.AppleCedarAppleRust.step2",
      "recommendation.AppleCedarAppleRust.step3",
    ],
  },




  // Beans diseases
  "angular_leaf_spot": {
    advice: [
      "recommendation.angularLeafSpot.disease",
      "recommendation.angularLeafSpot.step1",
      "recommendation.angularLeafSpot.step2",
      "recommendation.angularLeafSpot.step3",
      "recommendation.angularLeafSpot.step4",
      "recommendation.angularLeafSpot.step5",
      "recommendation.angularLeafSpot.step6",
      "recommendation.angularLeafSpot.step7",
      "recommendation.angularLeafSpot.step8",
      "recommendation.angularLeafSpot.step9",
      "recommendation.angularLeafSpot.step10",
    ],
  },
  "bean_rust": {
    advice: [
      "recommendation.beanRust.disease",
      "recommendation.beanRust.step1",
      "recommendation.beanRust.step2",
      "recommendation.beanRust.step3",
      "recommendation.beanRust.step4",
      "recommendation.beanRust.step5",
      "recommendation.beanRust.step6",
      "recommendation.beanRust.step7",
      "recommendation.beanRust.step8",
    ],
  },
  "healthy": {
    advice: [
      "recommendation.healthy.disease",
      "recommendation.healthy.step1",
      "recommendation.healthy.step2",
      "recommendation.healthy.step3",
      "recommendation.healthy.step4",
      "recommendation.healthy.step5",
      "recommendation.healthy.step6",
      "recommendation.healthy.step7",
      "recommendation.healthy.step8",
    ],
  },

  // Cassava diseases
  "casssava__bacterial__blight": {
    advice: [
      "recommendation.casssavaBacterialBlight.disease",
      "recommendation.casssavaBacterialBlight.step1",
    ],
  },
  "casssava__brown__spot": {
    advice: [
      "recommendation.casssavaBrownSpot.disease",
      "recommendation.casssavaBrownSpot.step1",
      "recommendation.casssavaBrownSpot.step2",
      "recommendation.casssavaBrownSpot.step3",
      "recommendation.casssavaBrownSpot.step4",
    ],
  },
  "casssava__green__mite": {
    advice: [
      "recommendation.cassavaGreenMite.disease",
      "recommendation.cassavaGreenMite.step1",
      "recommendation.cassavaGreenMite.step2",
      "recommendation.cassavaGreenMite.step3",
      "recommendation.cassavaGreenMite.step4",
    ],
  },
  "casssava__mosaic": {
    advice: [
      "recommendation.casssavaMosaic.disease",
      "recommendation.casssavaMosaic.step1",
      "recommendation.casssavaMosaic.step2",
      "recommendation.casssavaMosaic.step3",
      "recommendation.casssavaMosaic.step4",
      "recommendation.casssavaMosaic.step5",
    ],
  },
  "casssava__healthy": {
    advice: [
      "recommendation.casssavaHealthy.disease",
      "recommendation.casssavaHealthy.step1",
      "recommendation.casssavaHealthy.step2",
      "recommendation.casssavaHealthy.step3",
      "recommendation.casssavaHealthy.step4",
      "recommendation.casssavaHealthy.step5",
      "recommendation.casssavaHealthy.step6",
      "recommendation.casssavaHealthy.step7",
      "recommendation.casssavaHealthy.step8",
    ],
  },

  // Maize diseases
  "maize___Cercospora_leaf_spot_Gray_leaf_spot": {
    advice: [
      "recommendation.maizeCercosporaLeafSpotGrayLeafSpot.disease",
      "recommendation.maizeCercosporaLeafSpotGrayLeafSpot.step1",
      "recommendation.maizeCercosporaLeafSpotGrayLeafSpot.step2",
      "recommendation.maizeCercosporaLeafSpotGrayLeafSpot.step3",
      "recommendation.maizeCercosporaLeafSpotGrayLeafSpot.step4",
      "recommendation.maizeCercosporaLeafSpotGrayLeafSpot.step5",
      "recommendation.maizeCercosporaLeafSpotGrayLeafSpot.step6",
      "recommendation.maizeCercosporaLeafSpotGrayLeafSpot.step7",
    ],
  },
  "maize___Common_rust": {
    advice: [
      "recommendation.maizeCommonrust.disease",
      "recommendation.maizeCommonrust.step1",
      "recommendation.maizeCommonrust.step2",
      "recommendation.maizeCommonrust.step3",
      "recommendation.maizeCommonrust.step4",
      "recommendation.maizeCommonrust.step5",
      "recommendation.maizeCommonrust.step6",
      "recommendation.maizeCommonrust.step7",
    ],
  },
  "maize___Northern_Leaf_Blight": {
    advice: [
      "recommendation.maizeNorthernLeafBlight.disease",
      "recommendation.maizeNorthernLeafBlight.step1",
      "recommendation.maizeNorthernLeafBlight.step2",
      "recommendation.maizeNorthernLeafBlight.step3",
      "recommendation.maizeNorthernLeafBlight.step4",
      "recommendation.maizeNorthernLeafBlight.step5",
      "recommendation.maizeNorthernLeafBlight.step6",
    ],
  },
  "maize___leaf__spot": {
    advice: [
      "recommendation.maizeLeafSpot.disease",
      "recommendation.maizeLeafSpot.step1",
      "recommendation.maizeLeafSpot.step2",
      "recommendation.maizeLeafSpot.step3",
      "recommendation.maizeLeafSpot.step4",
      "recommendation.maizeLeafSpot.step5",
      "recommendation.maizeLeafSpot.step6",
    ],
  },
  "maize___leaf__blight": {
    advice: [
      "recommendation.maizeleafblight.disease",
      "recommendation.maizeleafblight.step1",
      "recommendation.maizeleafblight.step2",
      "recommendation.maizeleafblight.step3",
      "recommendation.maizeleafblight.step4",
      "recommendation.maizeleafblight.step5",
      "recommendation.maizeleafblight.step6",
      "recommendation.maizeleafblight.step7",
      "recommendation.maizeleafblight.step8",
    ],
  },
  "maize___streak__virus": {
    advice: [
      "recommendation.maizeStreakVirus.disease",
      "recommendation.maizeStreakVirus.step1",
      "recommendation.maizeStreakVirus.step2",
      "recommendation.maizeStreakVirus.step3",
      "recommendation.maizeStreakVirus.step4",
      "recommendation.maizeStreakVirus.step5",
      "recommendation.maizeStreakVirus.step6",
    ],
  },
  "maize___healthy": {
    advice: [
      "recommendation.maizeHealthy.disease",
      "recommendation.maizeHealthy.step1",
      "recommendation.maizeHealthy.step2",
      "recommendation.maizeHealthy.step3",
      "recommendation.maizeHealthy.step4",
      "recommendation.maizeHealthy.step5",
      "recommendation.maizeHealthy.step6",
      "recommendation.maizeHealthy.step7",
      "recommendation.maizeHealthy.step8",
    ],
  },
  "maize___fall__armyworm": {
    advice: [
      "recommendation.maizeFallArmyworm.disease",
      "recommendation.maizeFallArmyworm.step1",
      "recommendation.maizeFallArmyworm.step2",
      "recommendation.maizeFallArmyworm.step3",
      "recommendation.maizeFallArmyworm.step4",
      "recommendation.maizeFallArmyworm.step5",
    ],
  },
  "maize___grass__hoper": {
    advice: [
      "recommendation.maizeGrassHoper.disease",
      "recommendation.maizeGrassHoper.step1",
      "recommendation.maizeGrassHoper.step2",
      "recommendation.maizeGrassHoper.step3",
    ],
  },
  "maize___leaf__beetle": {
    advice: [
      "recommendation.maizeLeafBeetle.disease",
      "recommendation.maizeLeafBeetle.step1",
      "recommendation.maizeLeafBeetle.step2",
      "recommendation.maizeLeafBeetle.step3",
    ],
  },


  // Pepper diseases
  "Pepper,_bell___Bacterial_spot": {
    advice: [
      "recommendation.PepperBellBacterialSpot.disease",
      "recommendation.PepperBellBacterialSpot.step1",
      "recommendation.PepperBellBacterialSpot.step2",
      "recommendation.PepperBellBacterialSpot.step3",
      "recommendation.PepperBellBacterialSpot.step4",
      "recommendation.PepperBellBacterialSpot.step5",
      "recommendation.PepperBellBacterialSpot.step6",
      "recommendation.PepperBellBacterialSpot.step7",
      "recommendation.PepperBellBacterialSpot.step8",
      "recommendation.PepperBellBacterialSpot.step9",
    ],
  },
  "Pepper,_bell___healthy": {
    advice: [
      "recommendation.PepperBellHealthy.disease",
      "recommendation.PepperBellHealthy.step1",
      "recommendation.PepperBellHealthy.step2",
      "recommendation.PepperBellHealthy.step3",
      "recommendation.PepperBellHealthy.step4",
      "recommendation.PepperBellHealthy.step5",
      "recommendation.PepperBellHealthy.step6",
      "recommendation.PepperBellHealthy.step7",
      "recommendation.PepperBellHealthy.step8",
    ],
  },

  // Unknown/Healthy cases
  // "Unknown": {
  //   advice: [
  //     "recommendation.unknown.disease",
  //     "recommendation.unknown.step1",
  //   ],
  // },
};

export default recommendations;
