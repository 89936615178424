import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import {
  Box,
  Select,
  MenuItem,
  Typography,
  IconButton,
  CircularProgress,
  DialogActions,
  Slider,
  Menu,
  Alert,
} from "@mui/material";
import { height, styled } from "@mui/system";
import {
  CloudUpload,
  CameraAlt,
  PlayArrow,
  Pause,
  WhatsApp,
  Facebook,
  Sms,
  VolumeUp,
  ArrowDropDown,
  Home,
} from "@mui/icons-material";
import { Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
import pdf from "../image/invoices.pdf";
import Cropper from "react-easy-crop";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Logo from "../image/New Hyphen Tech Logo (7).png";
import LogoResponsive from "../image/AgriHyphen AI.png";
import recommendations from "./recommandations";
import { languageFlags } from "../i18n";
import { useSpeechSynthesis } from "react-speech-kit";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PolicyDialog from "../components/PolicyDialog";
import GoogleFormsDialog from "../components/GoogleFormsDialog";
import { Close as CloseIcon } from "@mui/icons-material";
import DonatePage from './DonatePage';
import { getAudioUrl } from 'google-tts-api';
import demoVideo from '../image/demo-video.mp4';  // Adjust path as needed
import ReactDOM from 'react-dom';

// Animated buttons for feedback and donation
const buttonVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const formUrls = {
  en: "https://docs.google.com/forms/d/e/1FAIpQLSfg4agy9D9P3A6wvMSlxS-JPuJ80PdLjygsZOi3_t7m086-Wg/viewform?embedded=true",
  fr: "https://docs.google.com/forms/d/e/1FAIpQLSdbL-Yf19vt8PO50FM2gHOTUr4oMQbuR1XfDcWPrh5G9abMXQ/viewform?embedded=true",
  swa: "https://docs.google.com/forms/d/e/1FAIpQLSdbL-Yf19vt8PO50FM2gHOTUr4oMQbuR1XfDcWPrh5G9abMXQ/viewform?embedded=true", // French form
  kir: "https://docs.google.com/forms/d/e/1FAIpQLSdbL-Yf19vt8PO50FM2gHOTUr4oMQbuR1XfDcWPrh5G9abMXQ/viewform?embedded=true"  // French form
};

const getFormUrl = (language) => {
  switch (language) {
    case "kir":
      return formUrls["fr"]; // Default to French for Kirundi
    case "swa":
      return formUrls["en"]; // Default to English for Swahili
    default:
      return formUrls[language] || formUrls["en"]; // Default to English if language not found
  }
};

// Updated Styled Components for light mode
const Container = styled(Box)({
  minHeight: "100vh",
  backgroundColor: "#ffffff",
  color: "#000000",
});

const Header = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  padding: "12px 24px",
  backgroundColor: "#ffffff",
  borderBottom: "1px solid #e5e5e5",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  zIndex: 1000,
  "@media (max-width: 768px)": {
    flexDirection: "row",
    padding: "12px",
    height: "auto",
  },
});

const LogoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  minWidth: "180px",
  "@media (max-width: 768px)": {
    minWidth: "auto",
    gap: "8px",
  },
});

const SelectContainer = styled(Box)({
  flex: "0 1 200px",
  display: "flex",
  justifyContent: "center",
  margin: "0 auto",
  marginRight: "390px",
  "@media (max-width: 1024px)": {
    marginRight: "100px",
  },
  "@media (max-width: 768px)": {
    flex: "1",
    marginRight: "8px",
    marginLeft: "8px",
  },
});

const MainContent = styled(Box)({
  paddingTop: "80px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  maxWidth: "800px",
  margin: "0 auto",
  padding: "20px",
});

const UploadArea = styled(Box)({
  backgroundColor: "#f7f7f8",
  borderRadius: "8px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  transition: "background-color 0.3s",
  border: "1px dashed #c5c5d2",
  "&:hover": {
    backgroundColor: "#f0f0f1",
  },
});

const ResultArea = styled(motion.div)({
  backgroundColor: "#f7f7f8",
  borderRadius: "8px",
  padding: "20px",
  marginTop: "20px",
  border: "1px solid #e5e5e5",
});

const StyledSelect = styled(Select)({
  minWidth: 250,
  color: "#000000",
  backgroundColor: "#ffffff",
  textAlign: "left",
  border: "2px solid #10a37f",
  borderRadius: "8px",
  "& .MuiSelect-select": {
    padding: "10px 14px",
    color: "#10a37f",
    fontWeight: 500,
  },
  "& .MuiSelect-icon": {
    color: "#10a37f",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover": {
    backgroundColor: "rgba(16, 163, 127, 0.04)",
  },
  "@media (max-width: 768px)": {
    minWidth: "auto",
    border: "2px solid #10a37f", // Highlight border
    borderRadius: "8px",
    "& .MuiSelect-select": {
      padding: "8px 10px",
      fontSize: "0.8rem",
      fontWeight: 500,
      color: "#10a37f", // Matching color
    },
    "& .MuiMenuItem-root": {
      fontSize: "0.8rem",
    },
    "& .MuiSelect-icon": {
      color: "#10a37f", // Matching color for dropdown icon
    },
    "&:hover": {
      backgroundColor: "rgba(16, 163, 127, 0.04)", // Subtle hover effect
    },
  },
});

// Model options
const MODELS = [
  { value: "apple", label: "Apple Disease Detection" },
  { value: "beans", label: "Beans Disease Detection" },
  { value: "cassava", label: "Cassava Disease Detection" },
  { value: "maize", label: "Maize Disease Detection" },
  { value: "pepper", label: "Pepper Disease Detection" },
  { value: "potato", label: "Potato Disease Detection" },
  { value: "tomatoes", label: "Tomatoes Disease Detection" },
];

// Add new styled component for language selector
const LanguageSelector = styled(Box)({
  position: "absolute",
  right: 20,
  top: 20,
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const FlagIcon = styled("img")({
  width: 24,
  height: 24,
  borderRadius: "50%",
  cursor: "pointer",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const ImagePreviewContainer = styled(Box)({
  width: "100%",
  maxWidth: "600px",
  margin: "0 auto",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
});

const PreviewImage = styled("img")({
  width: "100%",
  height: "auto",
  display: "block",
});

const ResultContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  maxWidth: "800px",
  margin: "20px auto",
});

const RecommendationsContainer = styled(Box)({
  width: "100%",
  backgroundColor: "#f7f7f8",
  borderRadius: "8px",
  padding: "20px",
  margin: "0 auto",
});

const HeaderText = styled(Typography)({
  textAlign: "center",
  maxWidth: "600px",
  margin: "0 auto 24px auto",
  color: "#374151",
});

const HomeButton = styled(IconButton)({
  color: "#10a37f",
  border: "2px solid #10a37f",
  borderRadius: "8px",
  padding: "8px",
  "&:hover": {
    backgroundColor: "rgba(16, 163, 127, 0.04)",
  },
  "@media (max-width: 768px)": {
    padding: "6px",
  },
});
// Add styled component for footer
const Footer = styled(Box)({
  textAlign: "center",
  padding: "20px",
  marginTop: "auto",
  fontSize: "0.8rem",
  color: "#666",
  "@media (max-width: 768px)": {
    fontSize: "0.7rem",
    padding: "16px",
  },
});

const FooterLink = styled("a")({
  color: "#10a37f",
  textDecoration: "none",
  fontWeight: 500,
  "&:hover": {
    textDecoration: "underline",
  },
});

export default function ImageUploadSection() {
  const { t, i18n } = useTranslation();
  const [selectedModel, setSelectedModel] = useState("");
  const [image, setImage] = useState(null);
  const [prediction, setPrediction] = useState(null);
  const [predictionLoading, setPredictionLoading] = useState(false);
  const [predictionError, setPredictionError] = useState(null);
  const [recommendation, setRecommendation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDemo, setShowDemo] = useState(true);
  const [currentRecommendations, setCurrentRecommendations] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const { speak, cancel, speaking } = useSpeechSynthesis();
  const [audioLoading, setAudioLoading] = useState(false);
  const [modelAnchorEl, setModelAnchorEl] = useState(null);
  const [showPolicy, setShowPolicy] = useState(false);
  const [showGoogleForm, setShowGoogleForm] = useState(false);
  const [showLanguageSelect, setShowLanguageSelect] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openDonation, setOpenDonation] = useState(false);

  // Handle open/close of feedback and donation modals
  const handleFeedbackClick = () => setOpenFeedback(true);
  const handleDonationClick = () => setOpenDonation(true);
  const handleCloseFeedback = () => setOpenFeedback(false);
  const handleCloseDonation = () => setOpenDonation(false);

  // Add this to get current language
  const currentLanguage = i18n.language;

  // Language handling
  const handleLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageChange = (lang) => {
    localStorage.setItem('selectedLanguage', lang);
    i18n.changeLanguage(lang);
    setShowLanguageSelect(false);
    setAnchorEl(null);

    // Check if form was ever completed (regardless of language)
    const formCompleted = localStorage.getItem('formCompleted');
    if (!formCompleted) {
      setShowGoogleForm(true);
    } else {
      // If form is completed, check policy
      const policyAccepted = localStorage.getItem('policyAccepted');
      if (!policyAccepted) {
        setShowPolicy(true);
      }
    }
  };

  // Dropzone configuration
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setPreviewImage(URL.createObjectURL(file));
      setCropperOpen(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: false,
  });

  // Cropper handlers
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const uploadImageToServer = async (imageData) => {
    try {
      const response = await axios.post(
        `https://agridata-opal.vercel.app/api/upload/image`,
        {
          image: imageData, // Base64 string
          modelName: selectedModel,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        console.log("Image uploaded successfully:", response.data.url);
        return response.data.url;
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      console.error("Upload error:", error.message);
      throw error;
    }
  };

  const showCroppedImage = async () => {
    try {
      if (!selectedModel) {
        alert(t("Please select a model first"));
        return;
      }

      // Get the cropped image
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      setPreviewImage(croppedImage);
      setCropperOpen(false);

      // Start prediction immediately with the cropped image
      await sendImageForPrediction(croppedImage);

      // Try uploading the image in the background (optional)
      try {
        const uploadedUrl = await uploadImageToServer(croppedImage);
        console.log("Image uploaded successfully:", uploadedUrl);
      } catch (uploadError) {
        console.warn("Image upload failed:", uploadError);
      }
    } catch (error) {
      console.error("Error processing image:", error);
    }
  };

  // Effect to update recommendations when language changes
  useEffect(() => {
    if (prediction?.class) {
      const diseaseRecommendations = recommendations[prediction.class]
        ?.advice || [
        "No recommendations for this picture. Consider retaking the picture with a clear, full display.",
      ];
      setCurrentRecommendations(diseaseRecommendations.map((key) => t(key)));
    }
  }, [i18n.language, prediction, t]);

  // Image prediction handler
  const sendImageForPrediction = async (croppedImg) => {
    if (!selectedModel) {
      alert(t("Please select a model first"));
      return;
    }

    setLoading(true);
    setPredictionLoading(true); // Add this line to show loader
    try {
      console.log("Starting prediction...");

      // Show loading overlay
      const loadingOverlay = (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0,0,0,0.7)",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <CircularProgress sx={{ color: "#10a37f" }} />
          <Typography sx={{ color: "white", fontWeight: 500 }}>
            {t("Analyzing your image...")}
          </Typography>
        </Box>
      );

      // Add loading overlay to DOM
      const overlayDiv = document.createElement("div");
      overlayDiv.id = "loading-overlay";
      document.body.appendChild(overlayDiv);
      ReactDOM.render(loadingOverlay, overlayDiv);

      // Rest of your existing prediction code...
      const blob = await fetch(croppedImg).then((res) => res.blob());
      const formData = new FormData();
      formData.append("file", blob, "image.jpg");

      const result = await axios.post(
        `https://agrihyphen-ai.onrender.com/predict/${selectedModel}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("Prediction result:", result.data);

      setPrediction(result.data);
      const diseaseRecommendations = recommendations[result.data.class]
        ?.advice || [
        "recommendation.unknown.disease",
        "recommendation.unknown.step1",
      ];
      setCurrentRecommendations(diseaseRecommendations.map((key) => t(key)));

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (error) {
      console.error("Prediction error:", error);
      alert(t("Error processing image. Please try again."));
    } finally {
      setLoading(false);
      setPredictionLoading(false); // Hide loader
      // Remove loading overlay
      const overlay = document.getElementById("loading-overlay");
      if (overlay) {
        ReactDOM.unmountComponentAtNode(overlay);
        overlay.remove();
      }
    }
  };

  // Sharing handlers
  const handleShare = (platform) => {
    // Use the specific URL for sharing
    const baseUrl = "https://agri.hyphentech.tech/test-ai";

    // Construct invitation message with the specific URL
    const text = t("share.inviteMessage", {
      url: baseUrl,
    }) || `Discover AgriHyphen AI - Your Smart Farming Assistant! 🌱\n\nDetect plant diseases instantly using AI technology. Try it now at: ${baseUrl}\n\n#SmartFarming #AgriTech #AI`;

    // Handle sharing based on platform
    switch (platform) {
      case "whatsapp":
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`;
        window.open(whatsappUrl, "_blank", "noopener,noreferrer");
        break;

      case "facebook":
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(baseUrl)}&quote=${encodeURIComponent(text)}`;
        window.open(facebookUrl, "_blank", "width=600,height=400,noopener,noreferrer");
        break;

      case "sms":
        if (/Android|iPhone/i.test(navigator.userAgent)) {
          // For mobile devices
          const smsUrl = `sms:?body=${encodeURIComponent(text)}`;
          window.open(smsUrl);
        } else {
          // For desktop - copy to clipboard
          navigator.clipboard.writeText(text).then(() => {
            alert(t("share.copied") || "Invitation text copied to clipboard!");
          });
        }
        break;

      default:
        console.error("Unsupported sharing platform");
    }
  };

  const handleImageCapture = (e) => {
    if (e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
      setCropperOpen(true);
    }
  };

  const handleSpeak = useCallback(async () => {
    if (speaking) {
      cancel();
      setIsPlaying(false);
      return;
    }

    if (!currentRecommendations.length) return;

    console.log("Current language:", i18n.language); // Debug log

    if (i18n.language === "swa") {
      // Use Google TTS for Swahili
      try {
        setAudioLoading(true);
        setIsPlaying(true);
        const text = currentRecommendations.join(". ");
        
        // Debug logs
        console.log("Swahili text to speak:", text);
        
        const url = await getAudioUrl(text, {
          lang: 'sw-TZ', // Changed to more specific Swahili code
          slow: false,
          host: 'https://translate.google.com',
        });
        
        console.log("Generated URL:", url); // Debug log
        
        const audio = new Audio();
        
        audio.addEventListener('canplaythrough', () => {
          audio.play().catch(error => {
            console.error("Playback error:", error);
            setIsPlaying(false);
            setAudioLoading(false);
            alert(t("Error playing audio"));
          });
        });

        audio.addEventListener('ended', () => {
          setIsPlaying(false);
          setAudioLoading(false);
        });

        audio.addEventListener('error', (e) => {
          console.error("Audio error:", e);
          setIsPlaying(false);
          setAudioLoading(false);
          alert(t("Error playing audio"));
        });

        audio.src = url;
        
      } catch (error) {
        console.error("Error with Swahili TTS:", error);
        setIsPlaying(false);
        setAudioLoading(false);
        alert(t("Error playing audio"));
      }
    } else if (i18n.language === "en" || i18n.language === "fr") {
      // Use browser TTS for English and French
      setIsPlaying(true);
      const text = currentRecommendations.join(". ");
      const language = i18n.language === "en" ? "en-US" : "fr-FR";
      
      speak({
        text,
        voice: window.speechSynthesis
          .getVoices()
          .find((voice) => voice.lang.startsWith(language)),
        onEnd: () => {
          setIsPlaying(false);
        },
      });
    } else {
      // For Kirundi or unsupported languages
      alert(t("Audio not available in this language"));
    }
  }, [speaking, cancel, currentRecommendations, i18n.language, speak, t]);

  // Add useEffect to initialize language from storage
  useEffect(() => {
    const storedLang = localStorage.getItem('selectedLanguage');
    const formCompleted = localStorage.getItem('formCompleted');
    const policyAccepted = localStorage.getItem('policyAccepted');

    if (!storedLang) {
      setShowLanguageSelect(true);
    } else {
      // Language exists, update i18n if needed
      if (storedLang !== i18n.language) {
        i18n.changeLanguage(storedLang);
      }
      
      // Then check other conditions
      if (!formCompleted) {
        setShowGoogleForm(true);
      } else if (!policyAccepted) {
        setShowPolicy(true);
      }
    }
  }, [i18n.language]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Add handler functions
  const handleModelMenu = (event) => {
    setModelAnchorEl(event.currentTarget);
  };

  const handleModelChange = (value) => {
    setSelectedModel(value);
    setModelAnchorEl(null);
  };

  const handleFormComplete = () => {
    localStorage.setItem("formCompleted", "true");
    setShowGoogleForm(false);

    const policyAccepted = localStorage.getItem("policyAccepted");
    if (!policyAccepted) {
      setShowPolicy(true);
    }
  };

  const handlePolicyAccept = () => {
    localStorage.setItem("policyAccepted", "true");
    setShowPolicy(false);
  };

  // Create a Language Selection Dialog component
  const LanguageSelectionDialog = ({ open, onLanguageSelect }) => {
    return (
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: "#10a37f",
          }}
        >
          <Typography variant="h5" component="span" fontWeight={600}>
            Select Language / Choisir la langue
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            {Object.entries(languageFlags).map(([code, { flag, label }]) => (
              <Button
                key={code}
                onClick={() => onLanguageSelect(code)}
                variant="outlined"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  p: 2,
                  borderColor: "#10a37f",
                  color: "#10a37f",
                  "&:hover": {
                    borderColor: "#0e906f",
                    bgcolor: "rgba(16, 163, 127, 0.04)",
                  },
                }}
              >
                <img src={flag} alt={label} style={{ width: 24, height: 24 }} />
                <Typography>{label}</Typography>
              </Button>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage && storedLanguage !== i18n.language) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  return (
    <Container>
      <LanguageSelectionDialog
        open={showLanguageSelect}
        onLanguageSelect={handleLanguageChange}
      />

      <GoogleFormsDialog
        open={showGoogleForm}
        onClose={handleFormComplete}
        language={i18n.language}
      />

      <PolicyDialog open={showPolicy} onAccept={handlePolicyAccept} />

      {!showLanguageSelect && !showGoogleForm && !showPolicy && (
        <MainContent>
          <Header>
            <LogoContainer>
              <img
                src={isMobile ? LogoResponsive : Logo}
                alt="Hyphen AI Logo"
                style={{
                  height: isMobile ? "32px" : "150px",
                  width: isMobile ? "32px" : "150px",
                  position: isMobile ? "relative" : "absolute",
                  marginRight: "10px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  display: { xs: "none", sm: "block" },
                  color: "#2d3436",
                }}
              ></Typography>
            </LogoContainer>

            <SelectContainer>
              {!isMobile ? (
                // Desktop version - keep the dropdown
                <StyledSelect
                  value={selectedModel}
                  onChange={(e) => setSelectedModel(e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    {t("Select a model")}
                  </MenuItem>
                  {MODELS.map((model) => (
                    <MenuItem key={model.value} value={model.value}>
                      {t(model.label)}
                    </MenuItem>
                  ))}
                </StyledSelect>
              ) : (
                // Mobile version - menu toggle
                <>
                  <Button
                    onClick={handleModelMenu}
                    sx={{
                      color: "#10a37f",
                      border: "2px solid #10a37f",
                      borderRadius: "8px",
                      padding: "6px 12px",
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      minWidth: "140px",
                      "&:hover": {
                        backgroundColor: "rgba(16, 163, 127, 0.04)",
                      },
                    }}
                    endIcon={<ArrowDropDown />}
                  >
                    {selectedModel
                      ? t(MODELS.find((m) => m.value === selectedModel)?.label)
                      : t("Select a model")}
                  </Button>
                  <Menu
                    anchorEl={modelAnchorEl}
                    open={Boolean(modelAnchorEl)}
                    onClose={() => setModelAnchorEl(null)}
                    PaperProps={{
                      sx: {
                        mt: 1,
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        minWidth: "200px",
                      },
                    }}
                  >
                    {MODELS.map((model) => (
                      <MenuItem
                        key={model.value}
                        onClick={() => handleModelChange(model.value)}
                        selected={selectedModel === model.value}
                        sx={{
                          fontSize: "0.8rem",
                          padding: "10px 16px",
                          "&:hover": {
                            backgroundColor: "rgba(16, 163, 127, 0.08)",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "rgba(16, 163, 127, 0.12)",
                            "&:hover": {
                              backgroundColor: "rgba(16, 163, 127, 0.16)",
                            },
                          },
                        }}
                      >
                        {t(model.label)}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </SelectContainer>

            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <HomeButton
                onClick={() => (window.location.href = "/")}
                title={t("Go to Homepage")}
              >
                <Home
                  sx={{
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                  }}
                />
              </HomeButton>

              <IconButton onClick={handleLanguageMenu}>
                <FlagIcon
                  src={languageFlags[i18n.language].flag}
                  alt={languageFlags[i18n.language].label}
                />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {Object.entries(languageFlags).map(
                  ([code, { flag, label }]) => (
                    <MenuItem
                      key={code}
                      onClick={() => handleLanguageChange(code)}
                      selected={i18n.language === code}
                    >
                      <FlagIcon
                        src={flag}
                        alt={label}
                        style={{ marginRight: 8 }}
                      />
                      {label}
                    </MenuItem>
                  )
                )}
              </Menu>
            </Box>
          </Header>

          {(previewImage || prediction) && (
            <ResultContainer sx={{ mt: 8 }}>
              <ImagePreviewContainer
                sx={{
                  display: "flex",
                  gap: 2,
                  mb: 2,
                  height: "256px",
                  width: "256px",
                }}
              >
                <PreviewImage src={previewImage} alt="Preview" />
              </ImagePreviewContainer>

              {prediction && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate="visible"
                  >
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleFeedbackClick}
                      sx={{
                        width: "160px",
                        height: "40px",
                        fontWeight: "600",
                        fontSize: "0.925rem",
                        borderRadius: "20px",
                        textTransform: "none",
                        boxShadow: "0 4px 12px rgba(16, 163, 127, 0.25)",
                        background: "linear-gradient(45deg, #10a37f 30%, #1ed7a7 90%)",
                        animation: "pulse 2s infinite",
                        '@keyframes pulse': {
                          '0%': {
                            boxShadow: '0 0 0 0 rgba(16, 163, 127, 0.4)',
                          },
                          '70%': {
                            boxShadow: '0 0 0 10px rgba(16, 163, 127, 0)',
                          },
                          '100%': {
                            boxShadow: '0 0 0 0 rgba(16, 163, 127, 0)',
                          },
                        },
                        '&:hover': {
                          background: "linear-gradient(45deg, #0d8c6d 30%, #19c094 90%)",
                          boxShadow: "0 6px 15px rgba(16, 163, 127, 0.3)",
                          transform: "translateY(-2px) scale(1.02)",
                        },
                        transition: "all 0.3s ease-in-out",
                      }}
                    >
                      {t("Feedback")}
                    </Button>
                  </motion.div>

                  <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate="visible"
                  >
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleDonationClick}
                      sx={{
                        width: "160px",
                        height: "40px",
                        fontWeight: "500",
                        fontSize: "0.875rem",
                        borderRadius: "20px",
                        textTransform: "none",
                        boxShadow: "0 2px 8px rgba(16, 163, 127, 0.15)",
                        background: "linear-gradient(45deg, #10a37f 30%, #1ed7a7 90%)",
                        '&:hover': {
                          background: "linear-gradient(45deg, #0d8c6d 30%, #19c094 90%)",
                          boxShadow: "0 4px 12px rgba(16, 163, 127, 0.2)",
                          transform: "translateY(-1px)",
                        },
                        transition: "all 0.2s ease-in-out",
                      }}
                    >
                      {t("Donate")}
                    </Button>
                  </motion.div>
                </Box>
              )}

              {/* Feedback Form Dialog */}
              <Dialog
                open={openFeedback}
                onClose={handleCloseFeedback}
                maxWidth="md"
                fullWidth
                PaperProps={{
                  sx: {
                    minHeight: '80vh',
                    maxHeight: '90vh',
                    overflowY: 'auto',
                  },
                }}
              >
                <DialogTitle>
                  {t("Feedback & Waiting List")}
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleCloseFeedback}
                    aria-label="close"
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <iframe
                    src={formUrls[i18n.language] || formUrls["fr"]} // Default to French form if language not found
                    width="100%"
                    height="600px"
                    frameBorder="0"
                    title="Feedback Form"
                    onLoad={() => console.log("Form loaded")}
                  />
                </DialogContent>
              </Dialog>

              {/* Donation Dialog */}
              <Dialog
                open={openDonation}
                onClose={handleCloseDonation}
                maxWidth="md"
                fullWidth
                PaperProps={{
                  sx: {
                    minHeight: '80vh', // Give enough height for the content
                    maxHeight: '90vh', // Prevent taking full screen
                    overflowY: 'auto', // Enable scrolling if content is too long
                  },
                }}
              >
                <DialogTitle sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleCloseDonation}
                    aria-label="close"
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                  <DonatePage />
                </DialogContent>
              </Dialog>

              {prediction && (
                <RecommendationsContainer>
                  {prediction && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 2,
                      }}
                    >
                      <IconButton
                        onClick={handleSpeak}
                        sx={{ color: "#10a37f" }}
                        disabled={audioLoading || i18n.language === "kir" || i18n.language === "swa"}
                      >
                        {speaking ? <Pause /> : <PlayArrow />}
                      </IconButton>
                      <VolumeUp sx={{ color: "#10a37f" }} />
                      {audioLoading && <CircularProgress size={24} />}
                      {(i18n.language === "kir" || i18n.language === "swa") && (
                        <Typography variant="body2" color="textSecondary">
                          {t("Audio will be available soon in")} {i18n.language === "kir" ? "Kirundi" : "Kiswahili"}
                        </Typography>
                      )}
                    </Box>
                  )}

                  {/* <Typography variant="h6" gutterBottom color="primary">
                    {t("Disease")}: {prediction.class}
                  </Typography> */}
                  <Typography variant="h6" gutterBottom color="primary">
                    {t("Confidence")}: {prediction.confidence}
                  </Typography>

                  {currentRecommendations.map((rec, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 + index * 0.1 }}
                    >
                      <Typography sx={{ mb: 1 }}>• {rec}</Typography>
                    </motion.div>
                  ))}

                  <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
                    <IconButton
                      onClick={() => handleShare("whatsapp")}
                      sx={{ color: "#10a37f" }}
                    >
                      <WhatsApp />
                    </IconButton>
                    <IconButton
                      onClick={() => handleShare("facebook")}
                      sx={{ color: "#10a37f" }}
                    >
                      <Facebook />
                    </IconButton>
                    <IconButton
                      onClick={() => handleShare("sms")}
                      sx={{ color: "#10a37f" }}
                    >
                      <Sms />
                    </IconButton>
                  </Box>
                </RecommendationsContainer>
              )}
            </ResultContainer>
          )}

          <HeaderText variant="h4" gutterBottom sx={{ mt: 8 }}>
            {t("imgtitle")}
          </HeaderText>
          <HeaderText variant="body1">{t("imgpart")}</HeaderText>

          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 3 }}
          >
            <Button
              variant="contained"
              component="label"
              startIcon={<CameraAlt />}
              sx={{
                backgroundColor: "#10a37f",
                "&:hover": { backgroundColor: "#0e906f" },
              }}
            >
              {t("Take a Picture")}
              <input
                type="file"
                hidden
                accept="image/*"
                capture="environment"
                onChange={handleImageCapture}
              />
            </Button>
          </Box>

          <UploadArea {...getRootProps()}>
            <input {...getInputProps()} />
            <CloudUpload sx={{ fontSize: 48, color: "#10a37f", mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              {t("Drop or click to upload an image")}
            </Typography>
            <Typography color="textSecondary">
              {t("Supported formats")}: JPG, PNG
            </Typography>
          </UploadArea>

          {!previewImage && !prediction && (
            <Box sx={{ mt: 4, textAlign: "center" }}>
              <Typography variant="h6" gutterBottom sx={{ color: "#10a37f" }}>
                {t("How to use Plant Disease Detection")}
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                <video
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  controls
                  autoPlay
                  loop
                  muted
                >
                  <source src={demoVideo} type="video/mp4" />
                  {t("Your browser does not support the video tag.")}
                </video>
              </Box>
            </Box>
          )}
        </MainContent>
      )}

      <Dialog
        open={cropperOpen}
        maxWidth="md"
        fullWidth
        onClose={() => {
          setCropperOpen(false);
          if (!prediction) {
            setPreviewImage(null);
          }
        }}
      >
        <DialogTitle>
          {selectedModel ? (
            t("Crop Image")
          ) : (
            <Typography color="error">
              {t("Please select a model first")}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ position: "relative", height: 400 }}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, zoom) => setZoom(zoom)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCropperOpen(false)}>{t("Cancel")}</Button>
          <Button
            onClick={showCroppedImage}
            variant="contained"
            disabled={!selectedModel}
          >
            {t("Analyze Image")}
          </Button>
        </DialogActions>
      </Dialog>

      {predictionLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0,0,0,0.5)",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
          <Typography sx={{ color: "white" }}>
            {t("Processing image...")}
          </Typography>
        </Box>
      )}

      {predictionError && (
        <Alert
          severity="error"
          onClose={() => setPredictionError(null)}
          sx={{
            position: "fixed",
            top: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          {predictionError}
        </Alert>
      )}

      <Footer>
        Made with ❤️ by{" "}
        <FooterLink
          href="https://www.hyphentech.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hyphen Tech
        </FooterLink>
      </Footer>
    </Container>
  );
}

// Utility function to get cropped image
async function getCroppedImg(imageSrc, croppedAreaPixels) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
      resolve(canvas.toDataURL("image/jpeg"));
    };
  });
}
