// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import fr from "./locales/fra.json";
import kir from "./locales/kir.json";
import swa from "./locales/swa.json";

// Import flag images
import ukFlag from './image/united-states-of-america-united-states-svgrepo-com.svg';
import frFlag from './image/flag-for-france-svgrepo-com.svg';
import biFlag from './image/flag-for-burundi-svgrepo-com.svg';
import taFlag from './image/flag-for-tanzania-svgrepo-com.svg';

import { getStoredLanguage } from './languageUtils';

export const languageFlags = {
  en: { flag: ukFlag, label: 'English' },
  fr: { flag: frFlag, label: 'Français' },
  kir: { flag: biFlag, label: 'Kirundi' },
  swa: { flag: taFlag, label: 'Kiswahili' }
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      kir: { translation: kir },
      swa: { translation: swa },
    },
    lng: getStoredLanguage(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  });

export default i18n;
