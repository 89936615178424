import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import pdf from "../image/invoices.pdf"; // Import PDF for donation instructions
import { ReactComponent as BitcoinIcon } from "../image/bitcoin-svgrepo-com.svg"; // Import the SVG as a React component
import BitcoinQR from "../image/Bitcoin-lightining.jpg";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DonatePage = () => {
  const { t } = useTranslation(); // Use translation hook
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ padding: 4, maxWidth: 900, margin: '0 auto', mt:"50px" }}>
      {/* Title Section */}
      <Typography variant="h4" gutterBottom>
        {t('titleDonate')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('subtitleDonate')}
      </Typography>

      {/* Introduction Section */}
      <Typography variant="body1" paragraph>
        {t('introDonate')}
      </Typography>

      {/* PDF Instructions Button */}
      <Box sx={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => window.open(pdf, '_blank')} // Open the PDF in a new window/tab
          sx={{
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#2e7d32', // Slightly darker green on hover
            },
          }}
        >
          {t('instructionsDonate')}
        </Button>
      </Box>

      {/* Bank Donation Information */}
      <Paper sx={{ marginTop: '40px', padding: '20px' }}>
        <Typography variant="h6" gutterBottom>
          {t('bankTitle')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('bankInfo')}
        </Typography>
        <Typography variant="body2" paragraph>
          {t('account')}
        </Typography>
      </Paper>

      {/* Bitcoin Donation Information */}
      <Paper sx={{ marginTop: '40px', padding: '20px' }}>
        <Typography variant="h6" gutterBottom>
          {t('bitcoinTitle')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('bitcoinInfo')}
        </Typography>

        {/* Display Bitcoin SVG icon */}
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <BitcoinIcon style={{ width: '100px', height: '100px' }} />
          <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
            Bitcoin Lightning Address
          </Typography>
          <img 
            src={BitcoinQR} 
            alt="Bitcoin Lightning QR Code"
            style={{ 
              width: '200px', 
              height: '200px',
              marginTop: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }} 
          />
        </Box>
      </Paper>

      {/* Video Section */}
      <Paper sx={{ marginTop: '40px', padding: '20px' }}>
        <Typography variant="h5" gutterBottom align="center">
          {t('titleVideo') || 'Watch Our Impact'}
        </Typography>
        <Typography variant="body1" paragraph align="center" sx={{ mb: 3 }}>
          {t('descriptionVideo') || 'See how your donation makes a difference'}
        </Typography>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%', // 16:9 Aspect Ratio
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            '& iframe': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
            },
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/KA7K8J2h48Q"
            title="AgriHyphen AI Impact"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </Paper>

      
    </Box>
  );
};

export default DonatePage;
