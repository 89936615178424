export const getStoredLanguage = () => {
    const storedLang = localStorage.getItem('selectedLanguage');
    // Map old language codes to new ones
    const languageMap = {
        'bi': 'kir',
        'sw': 'swa'
    };
    
    if (storedLang) {
        return languageMap[storedLang] || storedLang;
    }
    return 'en';
};

export const setStoredLanguage = (lang) => {
    localStorage.setItem('selectedLanguage', lang);
};

export const initializeLanguage = () => {
    const currentLang = getStoredLanguage();
    return currentLang;
};
 