import React from "react";
import { Container, Grid, Box, Typography, Button } from "@mui/material";
import img1 from "../image/vision.jpg";
import img2 from "../image/ATTN_10_2022_NEW_HABITS-1-300x242.png";
import img3 from "../image/181112_Blog_Feature_Mission.jpg";
import farmingImage from "../image/Agriculture-Sensor-Technology-Ritam-Gandhi.png";
import Team from "../image/team.webp";
import { Link } from "react-router-dom";
import ChallengesOpportunities from "./ChallengesOpportunities";
import { useTranslation } from "react-i18next";
import androidEmulatorImage from "../image/Android-removebg-preview.png";
import demoVideo from "../image/demo-video.mp4";

const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ mt: 10, py: 8, backgroundColor: "#f1f1f1" }}>
        <Container>
          <Box textAlign="center" mb={6}>
            <Typography variant="h4" component="h2" className="title-sm">
              {t("agrihyphen_title")}
            </Typography>
            <Typography variant="h3" className="title title-bg">
              {t("revolution_agriculture")}
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 10,
              mb: 10,
              py: 4,
              backgroundColor: "#fff",
              borderRadius: 4,
            }}
          >
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box sx={{ p: 4 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      mb: 3,
                      color: "#2e7d32",
                      fontSize: { xs: "24px", md: "36px" },
                    }}
                  >
                    AgriHyphen AI
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 4,
                      fontSize: { xs: "16px", md: "18px" },
                      lineHeight: 1.6,
                    }}
                  >
                    {t("agrihyphen_ai_desc")}
                  </Typography>
                  <Link to="/test-ai">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: "#2e7d32",
                        color: "#ffffff",
                        px: 2,
                        py: 1.5,
                        fontSize: "1.2rem",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
                        borderRadius: "8px",
                        transform: "translateY(-3px)",
                        transition:
                          "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          boxShadow: "0 6px 15px rgba(0, 0, 0, 0.7)",
                        },
                      }}
                    >
                      {t("test_agrihyphen_ai")}
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Box sx={{ textAlign: "center", p: 4 }}>
              <video
  width="100%"
  height="400px"
  src={demoVideo}  // Use the imported video
  title="Vision for AI Agriculture"
  autoPlay
  muted
  loop
  controls
  style={{
    borderRadius: "12px",
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
  }}
/>
              </Box>
            </Grid>
          </Box>

          {/* Vision Section */}
          <Grid container spacing={4} alignItems="center">
            {/* Description Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5" className="title-sm" sx={{ mb: 2 }}>
                  {t("our_vision")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 4,
                    fontSize: { xs: "16px", md: "20px" },
                    lineHeight: 1.6,
                  }}
                >
                  {t("vision_desc")}
                </Typography>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: "center", p: 4 }}>
                <img
                  src={img1}
                  alt="Vision for AI Agriculture"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "12px",
                    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Mission Section */}
          <Grid container spacing={4} alignItems="center" mt={6}>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: "center", p: 4 }}>
                <img
                  src={img3} // Replace with your image path
                  alt="AI Agriculture Mission"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "12px",
                    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5" className="title-sm" sx={{ mb: 2 }}>
                  {t("our_mission")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 4,
                    fontSize: { xs: "16px", md: "20px" },
                    lineHeight: 1.6,
                  }}
                >
                  {t("mission_desc")}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Who We Are Section */}
          <Grid container spacing={4} alignItems="center" mt={6}>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5" className="title-sm" sx={{ mb: 2 }}>
                  {t("who_we_are")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 4,
                    fontSize: { xs: "16px", md: "20px" },
                    lineHeight: 1.6,
                  }}
                >
                  {t("who_we_are_desc")}
                </Typography>
                <Link to="/team">
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      py: 1.5,
                      px: 4,
                      fontSize: "16px",
                      backgroundColor: "#2e7d32",
                      "&:hover": { backgroundColor: "#1b5e20" },
                    }}
                  >
                    {t("team")}
                  </Button>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: "center", p: 4 }}>
                <img
                  src={Team} // Replace with your image path
                  alt="Our Team"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "12px",
                    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Achievements Section */}
          <Grid container spacing={4} alignItems="center" mt={6}>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5" className="title-sm" sx={{ mb: 2 }}>
                  {t("our_achievements")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 4,
                    fontSize: { xs: "16px", md: "20px" },
                    lineHeight: 1.6,
                  }}
                >
                  {t("achievements_desc")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: "center", p: 4 }}>
                <img
                  src={farmingImage} // Replace with your image path
                  alt="Achievements in AI Agriculture"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "12px",
                    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Milestones and Goals Section */}
          <Grid container spacing={4} alignItems="center" mt={6}>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: "center", p: 4 }}>
                <img
                  src={img2} // Replace with your image path
                  alt="Future Goals"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "12px",
                    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5" className="title-sm" sx={{ mb: 2 }}>
                  {t("milestones_goals")}
                </Typography>
                <ul>
                  <li>{t("milestones.model_expansion")}</li>
                  <li>{t("milestones.enterprise_features")}</li>
                  <li>{t("milestones.localization_accessibility")}</li>
                  <li>{t("milestones.product_launch")}</li>
                </ul>
              </Box>
            </Grid>
          </Grid>

          <ChallengesOpportunities />

          {/* Call to Action for Investors */}
          {/* <Grid container spacing={4} alignItems="center" mt={6}>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5" className="title-sm" sx={{ mb: 2 }}>
                  Join Us
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 4,
                    fontSize: { xs: "16px", md: "20px" },
                    lineHeight: 1.6,
                  }}
                >
                  We invite donors, investors, and partners to join us in
                  revolutionizing African agriculture. Your support will help us
                  achieve our milestone of a full product launch by mid-2025 and
                  contribute to solving critical challenges in food production
                  and sustainability across the continent.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: "center", p: 4 }}>
                <img
                  src="" // Replace with your image path
                  alt="Join Us"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "12px",
                    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Box>
            </Grid>
          </Grid> */}
        </Container>
      </Box>
    </>
  );
};

export default LandingPage;
