import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Logo from "../image/New Hyphen Tech Logo (7).png";
import LogoResponsive from "../image/AgriHyphen AI.png";
import { languageFlags } from '../i18n'; // Import language flags
import { getStoredLanguage, setStoredLanguage } from '../languageUtils';
import { Link } from "react-router-dom";

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#ffffff',
  color: '#2d3436',
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
});

const StyledToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  padding: '8px 24px',
  '@media (max-width: 600px)': {
    padding: '8px 16px',
  },
});

const LogoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  minWidth: "180px",
  "@media (max-width: 768px)": {
    minWidth: "auto",
    gap: "8px",
  },
});

const NavButton = styled(Button)({
  color: '#2d3436',
  textTransform: 'none',
  fontSize: '15px',
  fontWeight: 500,
  padding: '6px 12px',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.03)',
  },
});

const FlagIcon = styled('img')({
  width: 24,
  height: 24,
  borderRadius: '50%',
  cursor: 'pointer',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
});

const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: 250,
    backgroundColor: '#ffffff',
    padding: '24px 16px',
  },
});

const HighlightedButton = styled(Button)({
  color: '#10a37f',
  border: '2px solid #10a37f',
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '15px',
  fontWeight: 500,
  padding: '6px 16px',
  marginLeft: '16px',
  '&:hover': {
    backgroundColor: 'rgba(16, 163, 127, 0.04)',
    border: '2px solid #10a37f',
  },
  '@media (max-width: 768px)': {
    fontSize: '13px',
    padding: '4px 12px',
    marginLeft: '8px',
  }
});

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const currentLang = getStoredLanguage();
    if (currentLang && currentLang !== i18n.language) {
      i18n.changeLanguage(currentLang);
    }
  }, [i18n]);

  const handleLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageChange = (lang) => {
    if (languageFlags[lang]) {
      setStoredLanguage(lang);
      i18n.changeLanguage(lang);
      setAnchorEl(null);
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const regularNavItems = [
    { path: "/", label: "nav1" },
    { path: "/info", label: "nav2" },
    { path: "/team", label: "nav3" },
    { path: "/donate", label: "Donate" }
  ];

  const highlightedItem = { label: "AgriHyphen AI", path: "/test-ai" };

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
      <LogoContainer>
              <img
                src={isMobile ? LogoResponsive : Logo}
                alt="Hyphen AI Logo"
                style={{
                  height: isMobile ? "32px" : "150px",
                  width: isMobile ? "32px" : "150px",
                  position: isMobile ? "relative" : "absolute",
                  marginRight: "10px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  display: { xs: "none", sm: "block" },
                  color: "#2d3436",
                }}
              ></Typography>
            </LogoContainer>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isMobile && (
            <>
              {regularNavItems.map((item) => (
                <Link 
                  key={item.path}
                  to={item.path}
                  style={{ textDecoration: 'none' }}
                >
                  <NavButton>
                    {item.label === "Donate" ? "Donate" : t(item.label)}
                  </NavButton>
                </Link>
              ))}
            </>
          )}

          <HighlightedButton href={highlightedItem.path}>
            {highlightedItem.label}
          </HighlightedButton>

          <IconButton onClick={handleLanguageMenu}>
            <FlagIcon 
              src={languageFlags[i18n.language]?.flag || languageFlags['en'].flag} 
              alt={languageFlags[i18n.language]?.label || languageFlags['en'].label}
            />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              sx: {
                mt: 1,
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              },
            }}
          >
            {Object.entries(languageFlags).map(([code, { flag, label }]) => (
              <MenuItem 
                key={code} 
                onClick={() => handleLanguageChange(code)}
                selected={i18n.language === code}
                sx={{
                  gap: 1.5,
                  minWidth: 150,
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(0,0,0,0.03)',
                  },
                }}
              >
                <FlagIcon src={flag} alt={label} style={{ marginRight: 8 }} />
                {label}
              </MenuItem>
            ))}
          </Menu>

          {isMobile && (
            <>
              <IconButton
                edge="end"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ 
                  ml: 1,
                  color: '#2d3436',
                }}
              >
                <MenuIcon />
              </IconButton>

              <StyledDrawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                <Box sx={{ mt: 2 }}>
                  <IconButton 
                    onClick={toggleDrawer(false)}
                    sx={{ 
                      position: 'absolute', 
                      right: 8, 
                      top: 8,
                      color: '#2d3436',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <List sx={{ mt: 4 }}>
                    {regularNavItems.map((item) => (
                      <ListItem 
                        key={item.path}
                        component={Link}
                        to={item.path}
                        sx={{ 
                          color: '#2d3436',
                          py: 1.5,
                          textDecoration: 'none',
                          '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.03)',
                          },
                        }}
                      >
                        <ListItemText 
                          primary={item.label === "Donate" ? "Donate" : t(item.label)}
                          primaryTypographyProps={{
                            fontSize: '15px',
                            fontWeight: 500,
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </StyledDrawer>
            </>
          )}
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  );
}
