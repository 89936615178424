import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation, Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import ImageUploadSection from "./components/ImageUploadSection";
import AgriHyphenAIExplanation from "./components/AgriHyphenAIExplanation";
import Team from "./components/Team";
import Loader from "./components/Loader";
import "./i18n"; // Initialize i18n for translations
import DonatePage from "./components/DonatePage";
import Dashboard from "./components/Dashboard";

// Create a theme instance
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

function LoaderWrapper({ isLoading, setIsLoading }) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1900);
    } else {
      setIsLoading(false);
    }
  }, [location, setIsLoading]);

  return isLoading ? (
    <Loader isLoading={isLoading} setIsLoading={setIsLoading} />
  ) : null;
}

function AppContent({ isLoading, setIsLoading }) {
  const location = useLocation();

  // Define paths where the Navbar should not be displayed
  const hideNavbarRoutes = ["/test-ai"];
  const shouldShowNavbar = !hideNavbarRoutes.includes(location.pathname);

  return (
    <>
      <LoaderWrapper isLoading={isLoading} setIsLoading={setIsLoading} />
      {!isLoading && (
        <>
          {/* Conditionally render Navbar */}
          {shouldShowNavbar && <Navbar />}
          <Routes>
            <Route path="/" element={<HeroSection />} />
            <Route path="/test-ai" element={<ImageUploadSection />} />
            <Route path="/info" element={<AgriHyphenAIExplanation />} />
            <Route path="/team" element={<Team />} />
            <Route path='/donate' element={<DonatePage/>}/>
            <Route path='/dashboard' element={<Dashboard/>}/>
          </Routes>
        </>
      )}
    </>
  );
}

function App() {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppContent isLoading={isLoading} setIsLoading={setIsLoading} />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
