import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider
} from '@mui/material';
import { Warning, Agriculture } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const PolicyDialog = ({ open, onAccept }) => {
  const { t } = useTranslation();

  const handleAccept = () => {
    localStorage.setItem('policyAccepted', 'true');
    onAccept();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          position: 'relative'
        }
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          color: '#10a37f',
          pb: 1
        }}
      >
        <Agriculture sx={{ fontSize: 28 }} />
        <Typography variant="h5" component="span" fontWeight={600}>
          {t('Disclaimer and Terms of Use')}
        </Typography>
      </DialogTitle>
      <Divider sx={{ mb: 2 }} />
      <DialogContent>
        <Typography variant="h6" gutterBottom color="primary">
          {t('Important Notice')}
        </Typography>
        
        <Box
          sx={{
            bgcolor: 'rgba(255, 193, 7, 0.15)',
            border: '1px solid #ffc107',
            color: 'warning.dark',
            p: 2,
            borderRadius: 1,
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Warning sx={{ fontSize: 24, color: 'warning.main' }} />
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {t('non_plant_warning')}
          </Typography>
        </Box>

        <Typography variant="body1" paragraph>
          {t('This system uses AgriHyphen AI to provide support for plant disease detection. However, it does not replace the advice of a qualified agronomist.')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t('By using this application, you acknowledge and agree that:')}
        </Typography>

        <Box component="ul" sx={{ pl: 2, m: 0 }}>
          <Typography component="li" variant="body1" paragraph>
            {t('The AgriHyphen AI system is based on limited data and resources, which may result in inaccurate outcomes in certain cases.')}
          </Typography>
          <Typography component="li" variant="body1" paragraph>
            {t('Recommendations provided must be validated and applied under the supervision of competent agricultural professionals.')}
          </Typography>
          <Typography component="li" variant="body1" paragraph>
            {t('This tool is designed to complement, not replace, the expertise of an agronomist or agricultural expert.')}
          </Typography>
          <Typography component="li" variant="body1" paragraph>
            {t('If the recommendations provided in the application seem unclear or difficult to apply, it is essential to contact an agronomist or agricultural expert for tailored advice.')}
          </Typography>
          <Typography component="li" variant="body1" paragraph>
            {t('For critical decisions regarding your crops, we strongly recommend consulting with a local agronomist or agricultural expert.')}
          </Typography>
        </Box>

        <Box
          sx={{
            bgcolor: '#e8f5e9',
            p: 2,
            borderRadius: 1,
            border: '1px solid #a5d6a7'
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {t('Photos uploaded through this application may be used for research purposes and to improve the performance of future AgriHyphen AI models for the common good.')}
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ mt: 2 }}>
          {t('By using the application, you consent to these terms and to our commitment to continuously improve the service to meet farmers\' needs.')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button
          onClick={handleAccept}
          variant="contained"
          size="large"
          sx={{
            bgcolor: '#10a37f',
            '&:hover': {
              bgcolor: '#0e906f'
            },
            px: 4
          }}
        >
          {t('I Understand and Accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PolicyDialog;
