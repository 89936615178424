import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const formUrls = {
  'en': "https://docs.google.com/forms/d/e/1FAIpQLSdyH_p_YD8RKSLQoRuuZHwyaUSTxakk8pvDTmq3aVI8PT4WIg/viewform?embedded=true",
  'fr': "https://docs.google.com/forms/d/e/1FAIpQLSd_iMVrJkBOpqyS1FxOuGG53veDvU89iSpLpH8HZ9IKPGZFbA/viewform?embedded=true",
  'sw': "https://docs.google.com/forms/d/e/1FAIpQLSe9rF2uQ-uDo-lIveqkVrCwNruJ7AqF_IMrCERVWlTOC3UDiQ/viewform?embedded=true",
  'bi': "https://docs.google.com/forms/d/e/1FAIpQLSe0MOatda63i7tP4TRDHoMA_YfX7aqbshKEB4XxFXSnn_BR4g/viewform?embedded=true"
};

const formHeights = {
  'en': 2020,
  'fr': 1863,
  'sw': 2183,
  'bi': 1344
};

const GoogleFormsDialog = ({ open, onClose, language }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Sync with i18next language on mount
    const storedLang = localStorage.getItem('i18nextLng');
    if (storedLang && language !== storedLang) {
      i18n.changeLanguage(storedLang);
    }
  }, []);

  useEffect(() => {
    if (open) {
      setLoading(true);
      
      // Add message listener for form submission
      const handleMessage = (event) => {
        if (event.origin.includes('google.com')) {
          // Check if the message indicates form submission
          if (event.data && typeof event.data === 'string') {
            if (
              event.data.includes('formResponse') ||
              event.data.includes('Votre réponse a bien été enregistrée') ||
              event.data.includes('Your response has been recorded') ||
              event.data.includes('Jibu lako limerekodiwa') ||
              event.data.includes('Inyishu yawe yanditswe')
            ) {
              console.log('Form submitted, closing dialog...');
              setTimeout(() => {
                onClose();
              }, 1500);
            }
          }
        }
      };

      window.addEventListener('message', handleMessage);
      return () => window.removeEventListener('message', handleMessage);
    }
  }, [open, onClose]);

  const handleIframeLoad = () => {
    setLoading(false);
    
    // Add form submission detection script to iframe
    try {
      const iframe = document.querySelector('iframe');
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(`
          const form = document.querySelector('form');
          if (form) {
            form.addEventListener('submit', function() {
              window.parent.postMessage('formResponse', '*');
            });
          }
        `, '*');
      }
    } catch (error) {
      console.log('Error injecting script:', error);
    }
  };

  const currentFormUrl = formUrls[i18n.language] || formUrls['fr'] || formUrls['ki'] || formUrls['swa'];
  const currentHeight = formHeights[language] || formHeights['en'] || formHeights['ki'] || formHeights['swa'];

  return (
    <Dialog 
      open={open} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          height: { xs: '100vh', sm: `${currentHeight}px` },
          maxHeight: { xs: '100vh', sm: '90vh' },
          m: { xs: 0, sm: 2 },
          position: 'relative'
        }
      }}
    >
      <DialogTitle sx={{ 
        bgcolor: '#10a37f', 
        color: 'white',
        py: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h6">
          {t("Quick Survey")}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: 'white',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 0, overflow: 'hidden', position: 'relative' }}>
        {loading && (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'white',
            zIndex: 1
          }}>
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: '#10a37f', mb: 2 }} />
              <Typography variant="body1" color="textSecondary">
                {t("Loading survey...")}
              </Typography>
            </Box>
          </Box>
        )}
        
        <Box sx={{ 
          height: '100%', 
          overflow: 'hidden',
          '& iframe': {
            border: 'none',
            width: '100%',
            height: '100%'
          }
        }}>
          <iframe
            src={currentFormUrl}
            title="Google Form Survey"
            onLoad={handleIframeLoad}
            allow="accelerometer; autoplay; camera; encrypted-media; geolocation; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GoogleFormsDialog;

